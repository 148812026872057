import React, { useState } from 'react';

import BigBanner from '../components/Banners/BigBanner';
import { PrimaryButton } from '../components/Buttons/PrimaryButton';
import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import ContactForm from '../components/Forms/Contact';
import Modal from '../components/Modal/index';
import affiliateWorldConference from '../images/WhereToFindUs/AffiliateWorldConference.jpg';
import aseImage from '../images/WhereToFindUs/ase.jpg';
import ldfImage from '../images/WhereToFindUs/ldf.jpg';
import lenditImage from '../images/WhereToFindUs/lendit.jpg';
import mailconImage from '../images/WhereToFindUs/mailcon.jpg';
import moscowAffiliateSummit from '../images/WhereToFindUs/MoscowAffiliateSummit.jpg';
import olaImage from '../images/WhereToFindUs/ola.jpg';
import trafficConverionSummit from '../images/WhereToFindUs/TrafficConverionSummit.jpg';

import style from '../styles/pages/wheretofindus.scss';

import cn from 'classnames';

interface IWhereToFindUsProps {
  data: {
    allGhostPost: any;
  };
  location: {
    pathname: string;
  };
}

const WhereToFindUs = ({ data, location }: IWhereToFindUsProps) => {
  const [isContactModalShown, setContactModalShown] = useState(false);

  // Contact Modal Toggle
  const showContactModal = () => {
    setContactModalShown(true);
  };

  const hideContactModal = () => {
    setContactModalShown(false);
  };
  return (
    <>
      <MetaData location={location} data={{}} />
      <Layout isHome isWhiteHeader>
        <Modal
          className={style.contactModal}
          isShown={isContactModalShown}
          isCloseButtonShown
          onClose={hideContactModal}
        >
          <ContactForm />
        </Modal>

        <section className={style.hero}>
          <div className="container">
            <div className="d-flex flex-wrap">
              <div className="col-12">
                <h1>
                  Where to find us <br className="d-none d-sm-block" />
                  around the world.
                </h1>
              </div>
            </div>
          </div>
        </section>

        <section className={style.heading}>
          <div className="container">
            <div className="d-flex flex-wrap">
              <div className="col-12">
                <p>
                  We do our best to attend all the major global events
                  <br className="d-none d-sm-block" /> where we can meet new partners.
                </p>
                <PrimaryButton onClick={showContactModal}>Contact Us</PrimaryButton>
              </div>
            </div>
          </div>
        </section>

        <section className={style.whereToFindUs}>
          <div className="container">
            <div className="d-flex flex-wrap">
              <div className={cn('col-12 col-sm-8 col-md-6', style.colTitle)}>
                <h5>Events we plan to attend</h5>
              </div>
              <div className={cn('col-12 col-sm-4 col-md-6', style.colTitle)}>
                <p>Come to talk to us!</p>
              </div>
              <div className={cn('col-12 col-sm-6 col-lg-4', style.col)}>
                <img src={trafficConverionSummit} alt="Traffic and Conversion Summit" />
                <h5>Traffic and Conversion Summit</h5>
                <p>San Diego, CA</p>
              </div>
              <div className={cn('col-12 col-sm-6 col-lg-4', style.col)}>
                <img src={mailconImage} alt="LeadsCon" />
                <h5>LeadsCon</h5>
                <p>Las Vegas, NV</p>
              </div>
              <div className={cn('col-12 col-sm-6 col-lg-4', style.col)}>
                <img src={moscowAffiliateSummit} alt="Moscow Affiliate Summit" />
                <h5>Moscow Affiliate Summit</h5>
                <p>Moscow, Russia</p>
              </div>
              <div className={cn('col-12 col-sm-6 col-lg-4', style.col)}>
                <img src={lenditImage} alt="LendIt Fintech USA" />
                <h5>LendIt Fintech USA</h5>
                <p>New York, NY</p>
              </div>
              <div className={cn('col-12 col-sm-6 col-lg-4', style.col)}>
                <img src={affiliateWorldConference} alt="Affiliate World Conference" />
                <h5>Affiliate World Conference</h5>
                <p>Barcelona, Spain </p>
              </div>
              <div className={cn('col-12 col-sm-6 col-lg-4', style.col)}>
                <img src={olaImage} alt="OLA Policy Summit" />
                <h5>OLA Policy Summit</h5>
                <p>Washington DC</p>
              </div>
              <div className={cn('col-12 col-sm-6 col-lg-4', style.col)}>
                <img src={ldfImage} alt="LDF Summit" />
                <h5>LDF Summit</h5>
                <p>Lac du Flambeau, WI</p>
              </div>
              <div className={cn('col-12 col-sm-6 col-lg-4', style.col)}>
                <img src={aseImage} alt="Affiliate Summit East" />
                <h5>Affiliate Summit East</h5>
                <p>New York, NY</p>
              </div>
            </div>
          </div>
        </section>

        <BigBanner />
      </Layout>
    </>
  );
};

export default WhereToFindUs;
